import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  ActivityIndicator,
  SafeAreaView,
  StatusBar,
  Modal,
  Platform,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { firestore } from '../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import VendorCard from './VendorCard';
import VendorProfileScreen from './VendorProfileScreen';
import { StackNavigationProp } from '@react-navigation/stack';

type AllVendorsScreenProps = {
  route: any;
  navigation: StackNavigationProp<any>;
};

type Vendor = {
  vendorName: string;
  location: string;
  profilePicture?: string;
  userId: string;
  category: string;
  averageRating?: number;
  createdAt?: number;
  promoted?: boolean;
  promotionExpiration?: number | null;
};

export default function AllVendorsScreen({ route, navigation }: AllVendorsScreenProps) {
  const { category, location } = route.params;
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [sortOption, setSortOption] = useState<'popular' | 'newest' | 'adventurous'>('popular');

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const vendorCollection = collection(firestore, 'vendors');
        const vendorQuery = query(vendorCollection, where('location', '==', location), where('category', '==', category));
        const vendorQuerySnapshot = await getDocs(vendorQuery);

        const fetchedVendors: Vendor[] = vendorQuerySnapshot.docs.map((doc) => doc.data() as Vendor);
        
        const validVendors = fetchedVendors.map((vendor) => ({
          ...vendor,
          promoted: vendor.promoted && vendor.promotionExpiration && vendor.promotionExpiration > Date.now(),
        }));

        setVendors(validVendors);
        setLoading(false);
      } catch (error) {
        setErrorMessage('Error fetching vendors');
        setLoading(false);
      }
    };

    fetchVendors();
  }, [location, category]);

  const sortedVendors = () => {
    let sortedList = [...vendors];
    if (sortOption === 'popular') {
      sortedList.sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));
    } else if (sortOption === 'newest') {
      sortedList.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
    } else if (sortOption === 'adventurous') {
      sortedList.sort(() => Math.random() - 0.5);
    }
    return sortedList.sort((a, b) => (b.promoted ? 1 : 0) - (a.promoted ? 1 : 0));
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    if (query.trim() === '') {
      setVendors(vendors);
    } else {
      const filteredVendors = vendors.filter((vendor) =>
        vendor.vendorName.toLowerCase().includes(query.toLowerCase())
      );
      setVendors(filteredVendors);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="dark-content" />

      {/* Header with back button */}
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.navigate('HomeTabs')} style={styles.backButton}>
          <Ionicons name="arrow-back" size={28} color="#000000" />
        </TouchableOpacity>
        <Text style={styles.title}>All {category} in {location}</Text>
      </View>

      {/* Search Bar */}
      <View style={styles.searchContainer}>
        <Ionicons name="search" size={20} color="#333" />
        <TextInput
          style={styles.searchInput}
          placeholder="Search vendors..."
          value={searchQuery}
          onChangeText={handleSearch}
        />
      </View>

      {/* Sort Options */}
      <View style={styles.sortContainer}>
        <TouchableOpacity onPress={() => setSortOption('popular')} style={[styles.sortButton, sortOption === 'popular' && styles.activeSortButton]}>
          <Text style={styles.sortText}>Most Popular</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setSortOption('newest')} style={[styles.sortButton, sortOption === 'newest' && styles.activeSortButton]}>
          <Text style={styles.sortText}>Newest</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setSortOption('adventurous')} style={[styles.sortButton, sortOption === 'adventurous' && styles.activeSortButton]}>
          <Text style={styles.sortText}>Adventurous</Text>
        </TouchableOpacity>
      </View>

      {/* Vendor List */}
      {loading ? (
        <ActivityIndicator size="large" color="#FFB6C1" />
      ) : errorMessage ? (
        <Text style={styles.errorText}>{errorMessage}</Text>
      ) : (
        <FlatList
          data={sortedVendors()}
          keyExtractor={(item) => item.userId}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={styles.vendorContainer}
              onPress={() => {
                setSelectedVendor(item);
                setModalVisible(true);
              }}
            >
              <View style={styles.vendorCardWrapper}>
                {item.promoted && <Text style={styles.adLabel}>Ad</Text>}
                <VendorCard vendor={item} />
              </View>
            </TouchableOpacity>
          )}
          numColumns={Platform.OS === 'web' ? 4 : 2}
          columnWrapperStyle={styles.row}
          ListEmptyComponent={<Text style={styles.emptyText}>No vendors found</Text>}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.vendorList}
        />
      )}

      {/* Vendor Profile Modal */}
      {selectedVendor && (
        <Modal visible={modalVisible} animationType="slide" onRequestClose={() => setModalVisible(false)}>
          <VendorProfileScreen
            vendor={selectedVendor} 
            isExplorer={true}
            onClose={() => setModalVisible(false)}
          />
        </Modal>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    paddingTop: StatusBar.currentHeight || 10,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  backButton: {
    padding: 10,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#4A4A4A',
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: '#FFB6C1',
    marginBottom: 20,
    marginHorizontal: 20,
  },
  searchInput: {
    marginLeft: 10,
    flex: 1,
    fontSize: 16,
    color: '#a4a4a4',
  },
  vendorList: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  row: {
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  vendorContainer: {
    flex: 1,
    maxWidth: '48%',
    marginBottom: 15,
    alignItems: 'center',
  },
  vendorCardWrapper: {
    position: 'relative',
    width: '100%',
  },
  emptyText: {
    textAlign: 'center',
    color: '#7f95e4',
    marginTop: 20,
  },
  errorText: {
    textAlign: 'center',
    color: 'red',
    marginTop: 20,
  },
  sortContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: 20,
    marginBottom: 20,
  },
  sortButton: {
    paddingVertical: 8,
    paddingHorizontal: 15,
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
    borderWidth: 2,
    borderColor: '#FFB6C1',
  },
  activeSortButton: {
    backgroundColor: '#FFB6C1',
  },
  sortText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#4A4A4A',
  },
  adLabel: {
    position: 'absolute',
    top: 5,
    left: 5,
    backgroundColor: '#fff',
    color: '#a4a4a4',
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 'bold',
    zIndex: 1,
  },
});
