import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator, Modal, TextInput, Image, Alert, Platform, ScrollView } from 'react-native';
import { auth, firestore, storage } from '../../firebaseConfig'; // Include storage for profile image
import { signOut } from 'firebase/auth';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import * as ImagePicker from 'expo-image-picker'; // For selecting profile image
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage methods

type ProfilePageProps = {
  navigation: StackNavigationProp<RootStackParamList, 'Profile'>;
};

export default function ProfilePage({ navigation }: ProfilePageProps) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(''); // User's name
  const [profileImage, setProfileImage] = useState<string | null>(null); // User's profile image
  const [errorMessage, setErrorMessage] = useState('');
  const [vendorExists, setVendorExists] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Flag to enable editing profile details
  const [showEditModal, setShowEditModal] = useState(false); // Modal to edit profile name

  const user = auth.currentUser;

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        try {
          const userRef = doc(firestore, 'users', user.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            setName(userData.name || '');
            setProfileImage(userData.profileImage || null);
          }

          const vendorRef = doc(firestore, 'vendors', user.uid);
          const vendorSnap = await getDoc(vendorRef);
          if (vendorSnap.exists()) {
            setVendorExists(true);
          }
        } catch (error) {
          setErrorMessage('Error fetching user data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigation.replace('WelcomeScreen');
      })
      .catch((error) => {
        setErrorMessage('Error signing out');
      });
  };

  const handleGoToVendorProfile = () => {
    if (vendorExists) {
      navigation.navigate('VendorProfile');
    } else {
      setShowPasswordModal(true);
    }
  };

  const handlePasswordSubmit = () => {
    const correctPassword = 'VENDOR_ACCESS'; // Example password for vendor access

    if (password === correctPassword) {
      setShowPasswordModal(false);
      navigation.navigate('VendorProfile');
    } else {
      setPasswordError('Incorrect password');
    }
  };

  const handleSaveProfile = async () => {
    if (user) {
      try {
        const userRef = doc(firestore, 'users', user.uid);
        await updateDoc(userRef, {
          name,
          profileImage,
        });
        setIsEditing(false);
        Alert.alert('Success', 'Profile updated successfully!');
      } catch (error) {
        setErrorMessage('Error updating profile');
      }
    }
  };

  const handleProfileImagePick = async () => {
    try {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Permission Denied', 'Camera roll permissions are required to change profile picture.');
          return;
        }
      }

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.7, // Reduce quality to optimize for mobile
      });

      if (!result.canceled && result.assets.length > 0) {
        const pickedImageUri = result.assets[0].uri;

        if (user) {
          // Fetch the image and convert to blob for uploading
          const response = await fetch(pickedImageUri);
          const blob = await response.blob();

          // Upload the image to Firebase Storage
          const storageRef = ref(storage, `profileImages/${user.uid}`);
          await uploadBytes(storageRef, blob);

          // Get the download URL after upload
          const downloadURL = await getDownloadURL(storageRef);

          // Update the profile image in Firestore
          setProfileImage(downloadURL);
          const userRef = doc(firestore, 'users', user.uid);
          await updateDoc(userRef, { profileImage: downloadURL });

          Alert.alert('Success', 'Profile image updated!');
        }
      }
    } catch (error) {
      Alert.alert('Error', 'There was an issue uploading your profile picture.');
      console.error('Error uploading profile image:', error);
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#FFB6C1" />
      </View>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
      <View style={styles.container}>
        {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}

        {/* Profile Image */}
        <TouchableOpacity onPress={handleProfileImagePick}>
          {profileImage ? (
            <Image source={{ uri: profileImage }} style={styles.profileImage} />
          ) : (
            <View style={styles.placeholderImage}>
              <Text style={styles.placeholderText}>Add Profile Image</Text>
            </View>
          )}
        </TouchableOpacity>

        {/* User's Name */}
        <Text style={styles.nameText}>{name || 'No name set'}</Text>

        {/* Buttons: Vendor Profile & Shared List */}
        <View style={styles.buttonRow}>
          <TouchableOpacity style={styles.switchRoleButton} onPress={handleGoToVendorProfile}>
            <Text style={styles.switchRoleButtonText}>Go to Vendor Profile</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.switchRoleButton} onPress={() => navigation.navigate('SharedListScreen')}>
            <Text style={styles.switchRoleButtonText}>Go to Shared List</Text>
          </TouchableOpacity>
        </View>

        {/* Settings Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Settings</Text>

          {/* Edit Profile */}
          <TouchableOpacity onPress={() => setShowEditModal(true)} style={styles.settingsItem}>
            <Text style={styles.settingsText}>Edit Profile</Text>
          </TouchableOpacity>

          {/* Account Settings */}
          <TouchableOpacity style={styles.settingsItem}>
            <Text style={styles.settingsText}>Account Settings</Text>
          </TouchableOpacity>

          {/* Privacy Settings */}
          <TouchableOpacity style={styles.settingsItem}>
            <Text style={styles.settingsText}>Privacy Settings</Text>
          </TouchableOpacity>
        </View>

        {/* Support Section */}
        <View style={styles.section}>
  <Text style={styles.sectionTitle}>Support</Text>
  <TouchableOpacity style={styles.settingsItem}>
    <Text style={styles.settingsText}>Help Center</Text>
  </TouchableOpacity>
  <TouchableOpacity style={styles.settingsItem}>
    <Text style={styles.settingsText}>Contact Us</Text>
  </TouchableOpacity>
  <TouchableOpacity 
    style={styles.settingsItem} 
    onPress={() => navigation.navigate('Recommendations')}>
    <Text style={styles.settingsText}>Recommend a Place</Text>
  </TouchableOpacity>
</View>

        {/* Legal Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Legal</Text>
          <TouchableOpacity style={styles.settingsItem}>
            <Text style={styles.settingsText}>Terms of Service</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.settingsItem}>
            <Text style={styles.settingsText}>Privacy Policy</Text>
          </TouchableOpacity>
        </View>

        {/* Logout Button */}
        <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
          <Text style={styles.logoutButtonText}>Logout</Text>
        </TouchableOpacity>

        {/* Edit Profile Modal */}
        <Modal transparent={true} visible={showEditModal} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Edit Name</Text>
              <TextInput
                style={styles.nameInput}
                value={name}
                onChangeText={setName}
                placeholder="Enter your name"
              />
              <TouchableOpacity style={styles.saveButton} onPress={() => { handleSaveProfile(); setShowEditModal(false); }}>
                <Text style={styles.saveButtonText}>Save</Text>
              </TouchableOpacity>

              <TouchableOpacity style={styles.cancelButton} onPress={() => setShowEditModal(false)}>
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/* Password Modal */}
        <Modal transparent={true} visible={showPasswordModal} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Enter Password</Text>
              <TextInput
                style={styles.passwordInput}
                placeholder="Enter Password"
                placeholderTextColor="#666"
                secureTextEntry={true}
                value={password}
                onChangeText={setPassword}
              />
              {passwordError ? <Text style={styles.errorText}>{passwordError}</Text> : null}

              <TouchableOpacity style={styles.submitButton} onPress={handlePasswordSubmit}>
                <Text style={styles.submitButtonText}>Submit</Text>
              </TouchableOpacity>

              <TouchableOpacity style={styles.cancelButton} onPress={() => setShowPasswordModal(false)}>
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    paddingBottom: 100,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 60,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImage: {
    width: 120,
    height: 120,
    borderRadius: 60,
    marginBottom: 20,
  },
  placeholderImage: {
    width: 120,
    height: 120,
    borderRadius: 60,
    backgroundColor: '#FFB6C1',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  placeholderText: {
    color: '#FFF',
    fontWeight: '600',
  },
  nameText: {
    fontSize: 24,
    fontWeight: '600',
    marginBottom: 30,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    marginBottom: 30,
  },
  switchRoleButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginHorizontal: 10,
    width: '45%',
    alignItems: 'center',
  },
  switchRoleButtonText: {
    color: '#333',
    fontWeight: '600',
  },
  section: {
    width: '90%',
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 10,
  },
  settingsItem: {
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#EDEDED',
  },
  settingsText: {
    fontSize: 16,
    color: '#333',
  },
  logoutButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 15,
    marginTop: 40,
  },
  logoutButtonText: {
    fontSize: 16,
    color: '#333',
    fontWeight: '600',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    borderRadius: 20,
    padding: 20,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '700',
    marginBottom: 20,
  },
  nameInput: {
    fontSize: 18,
    borderBottomWidth: 1,
    borderColor: '#FFB6C1',
    paddingBottom: 5,
    marginBottom: 20,
    width: '80%',
    textAlign: 'center',
  },
  saveButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 15,
    alignItems: 'center',
    marginBottom: 20,
  },
  saveButtonText: {
    color: '#FFF',
    fontWeight: '600',
  },
  cancelButton: {
    backgroundColor: '#F0F0F0',
    paddingVertical: 10,
    borderRadius: 15,
    alignItems: 'center',
    width: '100%',
  },
  cancelButtonText: {
    fontSize: 16,
    color: '#333',
    fontWeight: '600',
  },
  submitButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
  },
  submitButtonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
  passwordInput: {
    height: 50,
    width: '100%',
    backgroundColor: '#fff',
    borderColor: '#FFB6C1',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    marginBottom: 20,
    textAlign: 'center',
  }
});
