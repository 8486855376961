import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Linking,
  Image,
  ScrollView,
} from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';

type WelcomeScreenProps = {
  navigation: StackNavigationProp<RootStackParamList, 'LoginScreen'>;
};

export default function WelcomeScreen({ navigation }: WelcomeScreenProps) {
  const handleContinue = () => {
    navigation.navigate('LoginScreen');
  };

  const handleDownloadApp = (store: 'google' | 'apple') => {
    const urls = {
      google: 'https://play.google.com/store/apps',
      apple: 'https://www.apple.com/app-store/',
    };
    Linking.openURL(urls[store]);
  };

  return (
    <View style={styles.outerContainer}>
      <LinearGradient colors={['#FFDEE9', '#ffffff']} style={styles.gradient}>
        <ScrollView contentContainerStyle={styles.scrollContent}>
          {/* Logo Image */}
          <Image source={require('../../assets/logo9.png')} style={styles.logo} />

          <Text style={styles.subtitle}>Your bestie when visiting new places.</Text>

          {Platform.OS === 'web' ? (
            <>
              <TouchableOpacity onPress={handleContinue} style={styles.button}>
                <Text style={styles.buttonText}>Continue on Web</Text>
              </TouchableOpacity>

              <Text style={styles.orText}>OR</Text>

              <View style={styles.downloadSection}>
                <Text style={styles.downloadText}>DOWNLOAD THE APP</Text>
                <View style={styles.storeButtons}>
                  <TouchableOpacity
                    style={styles.storeButton}
                    onPress={() => handleDownloadApp('google')}
                  >
                    <MaterialCommunityIcons
                      name="google-play"
                      size={24}
                      color="#FFF"
                      style={styles.storeIcon}
                    />
                    <Text style={styles.storeButtonText}>Google Play</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.storeButton}
                    onPress={() => handleDownloadApp('apple')}
                  >
                    <Ionicons
                      name="logo-apple"
                      size={24}
                      color="#FFF"
                      style={styles.storeIcon}
                    />
                    <Text style={styles.storeButtonText}>App Store</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </>
          ) : (
            <TouchableOpacity onPress={handleContinue} style={styles.button}>
              <Text style={styles.buttonText}>Continue</Text>
            </TouchableOpacity>
          )}
        </ScrollView>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    height: '100vh', // Ensure it takes the full viewport height on web
    justifyContent: 'space-between',
  },
  gradient: {
    flex: 1,
    width: '100%',
  },
  scrollContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  logo: {
    width: 250,
    height: 250,
    marginBottom: 30,
    resizeMode: 'contain',
  },
  subtitle: {
    fontSize: 24,
    color: '#FFB6C1',
    marginBottom: 70,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    width: 350,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 60,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
  orText: {
    fontSize: 16,
    color: '#4A4A4A',
    marginVertical: 20,
    fontWeight: 'bold',
  },
  downloadSection: {
    alignItems: 'center',
    marginTop: 10,
  },
  downloadText: {
    fontSize: 16,
    color: '#4A4A4A',
    marginBottom: 35,
    fontWeight: 'bold',
  },
  storeButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  storeButton: {
    flexDirection: 'row',
    backgroundColor: '#000000',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 20,
    marginHorizontal: 20, // Increased margin between buttons
    alignItems: 'center',
  },
  storeIcon: {
    marginRight: 8,
  },
  storeButtonText: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: '600',
  },
});
