import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Modal,
  FlatList,
  StatusBar,
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { auth, firestore } from '../../firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';

type LocationSelectorProps = {
  navigation: StackNavigationProp<any>;
};

export default function LocationSelector({ navigation }: LocationSelectorProps) {
  const locations = ['Bali', 'Bangkok',];
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const selectLocation = async (location: string) => {
    setSelectedLocation(location);
    setModalVisible(false);

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);  // Ensure updating the 'users' collection
      await updateDoc(userRef, { location });  // Save selected location to Firestore
    }

    // Proceed to the category selection screen with the selected location
    navigation.navigate('CategorySelector', { location });
  };

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent={true} />
      <LinearGradient colors={['#FFDEE9', '#ffffff']} style={styles.background}>
        <View style={styles.topSpacing} />
        <Text style={styles.title}>Where are you headed?</Text>

        <TouchableOpacity style={styles.dropdownButton} onPress={() => setModalVisible(true)}>
          <Text style={styles.dropdownButtonText}>
            {selectedLocation ? `Selected: ${selectedLocation}` : 'Select a Location'}
          </Text>
          <Ionicons name="chevron-down" size={24} color="#333" />
        </TouchableOpacity>

        <Modal transparent={true} visible={isModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Select Location</Text>
              <FlatList
                data={locations}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity style={styles.locationCard} onPress={() => selectLocation(item)}>
                    <Text style={styles.locationText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  topSpacing: {
    height: 60, 
  },
  title: {
    fontSize: 32,
    fontWeight: '800',
    color: '#4A4A4A',
    marginBottom: 30,
    textAlign: 'center',
  },
  dropdownButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF0F6',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 25,
    width: 300,
    justifyContent: 'space-between',
    borderWidth: 2,
    borderColor: '#FFB6C1',
  },
  dropdownButtonText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    marginHorizontal: 20,
    borderRadius: 15,
    padding: 20,
    maxHeight: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
  },
  locationCard: {
    backgroundColor: '#FFF0F6',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
    width: 250,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#FFB6C1',
  },
  locationText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
  closeModalButton: {
    marginTop: 20,
    backgroundColor: '#FFB6C1',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 15,
  },
  closeModalText: {
    fontSize: 16,
    color: '#333',
    fontWeight: '600',
  },
});
