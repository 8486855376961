import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Modal,
  ScrollView,
  Image,
  Dimensions,
  Linking,
  Alert,
  Platform,
} from 'react-native';
import { firestore, auth, storage } from '../../firebaseConfig'; 
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove, collection, query, where, getDocs, addDoc, orderBy } from 'firebase/firestore';
import { Ionicons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';

type VendorProfileScreenProps = {
  navigation?: StackNavigationProp<RootStackParamList, 'VendorProfile'>;
  vendor?: any;
  isExplorer?: boolean;
  onClose?: () => void;
};

// Utility function to get the currency symbol based on location
const getCurrencySymbolForLocation = (location: string | null) => {
  const locationCurrencyMap: { [key: string]: string } = {
    'Bali': 'IDR',
    'Bangkok': 'THB',
    'Copenhagen': 'DKK',
    'Florence': 'EUR',
    'London': 'GBP',
    'Nice': 'EUR',
    'New York': 'USD',
    'Paris': 'EUR',
    'Tokyo': 'JPY',
  };

  return location ? locationCurrencyMap[location] || 'USD' : 'USD'; // Default to USD if location not found
};

// Confirmation dialog function
const confirmDelete = (item: string, callback: () => void) => {
  if (Platform.OS === 'web') {
    // Use window.confirm for web
    if (window.confirm(`Are you sure you want to delete ${item}?`)) {
      callback();
    }
  } else {
    // Use Alert.alert for mobile
    Alert.alert(
      'Confirm Delete',
      `Are you sure you want to delete ${item}?`,
      [
        { text: 'Cancel', style: 'cancel' },
        { text: 'Delete', style: 'destructive', onPress: callback },
      ],
      { cancelable: true }
    );
  }
};

export default function VendorProfileScreen({ vendor, isExplorer, onClose, navigation }: VendorProfileScreenProps) {
  const [vendorName, setVendorName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [googleMapsLocation, setGoogleMapsLocation] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [newPostModalVisible, setNewPostModalVisible] = useState(false);
  const [newPostImage, setNewPostImage] = useState<string | null>(null);
  const [newPostCaption, setNewPostCaption] = useState('');
  const [newPostPrice, setNewPostPrice] = useState(''); 
  const [posts, setPosts] = useState<Array<{ image: string; caption: string; price: string; aspectRatio: number }>>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null); 
  const [isFavorited, setIsFavorited] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false); 
  const [friendEmail, setFriendEmail] = useState('');
  const [sharedVendors, setSharedVendors] = useState<Array<any>>([]);
  const [friendsList, setFriendsList] = useState<string[]>([]); 
  const [selectedFriend, setSelectedFriend] = useState<string | null>(null); 
  const [loadingFriends, setLoadingFriends] = useState(true); 
  const [showRatingReviewModal, setShowRatingReviewModal] = useState(false);
  const [showAllReviewsModal, setShowAllReviewsModal] = useState(false);

  // State for reviews
  const [averageRating, setAverageRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [newRating, setNewRating] = useState(0);
  const [newReviewText, setNewReviewText] = useState('');

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchVendorProfile = async () => {
      if (vendor) {
        if (!vendor.posts) {
          try {
            const vendorRef = doc(firestore, 'vendors', vendor.userId);
            const vendorSnap = await getDoc(vendorRef);
            if (vendorSnap.exists()) {
              const vendorData = vendorSnap.data();
              setVendorName(vendorData.vendorName);
              setDescription(vendorData.description);
              setSelectedLocation(vendorData.location);
              setSelectedCategory(vendorData.category);
              setGoogleMapsLocation(vendorData.googleMapsLocation || null);
              setProfilePicture(vendorData.profilePicture || null);
              setPosts(vendorData.posts || []);
            }
          } catch (error) {
            setErrorMessage('Error fetching vendor profile');
          }
        } else {
          setVendorName(vendor.vendorName);
          setDescription(vendor.description);
          setSelectedLocation(vendor.location);
          setSelectedCategory(vendor.category);
          setGoogleMapsLocation(vendor.googleMapsLocation || null);
          setProfilePicture(vendor.profilePicture || null);
          setPosts(vendor.posts || []);
        }
        setLoading(false);
      } else if (currentUser) {
        try {
          const vendorRef = doc(firestore, 'vendors', currentUser.uid);
          const vendorSnap = await getDoc(vendorRef);
          if (vendorSnap.exists()) {
            const vendorData = vendorSnap.data();
            setVendorName(vendorData.vendorName);
            setDescription(vendorData.description);
            setSelectedLocation(vendorData.location);
            setSelectedCategory(vendorData.category);
            setGoogleMapsLocation(vendorData.googleMapsLocation || null);
            setProfilePicture(vendorData.profilePicture || null);
            setPosts(vendorData.posts || []);
          }
        } catch (error) {
          setErrorMessage('Error fetching vendor profile');
        } finally {
          setLoading(false);
        }
      }
    };

    const checkIfFavorited = async () => {
      if (isExplorer && currentUser && vendor) {
        try {
          const userRef = doc(firestore, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            if (userData.favorites && userData.favorites.includes(vendor.userId)) {
              setIsFavorited(true); 
            }
          }
        } catch (error) {
          console.error('Error checking if vendor is favorited:', error);
        }
      }
    };

    fetchVendorProfile();
    checkIfFavorited();
    fetchSharedVendors(); // Call to fetch shared vendors
    fetchVendorRatingsAndReviews(); // Fetch vendor ratings and reviews

  }, [vendor, currentUser, isExplorer]);

  const fetchSharedVendors = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userDocRef);
  
        if (userSnap.exists()) {
          const userData = userSnap.data();
          const sharedList = userData.sharedList || [];
  
          const vendors = await Promise.all(
            sharedList.map(async (sharedItem: any) => {
              try {
                const vendorDocRef = doc(firestore, 'vendors', sharedItem.vendorId);
                const vendorSnap = await getDoc(vendorDocRef);
                return vendorSnap.exists() ? vendorSnap.data() : null;
              } catch (vendorError) {
                console.error(`Error fetching vendor with ID ${sharedItem.vendorId}:`, vendorError);
                return null; 
              }
            })
          );
  
          setSharedVendors(vendors.filter(vendor => vendor !== null));

          const sharedFriends = sharedList.map((item: any) => item.sharedBy).filter(Boolean); 
          setFriendsList([...new Set(sharedFriends)]); 
        } else {
          console.error('User document not found');
        }
      } catch (error) {
        console.error('Error fetching shared vendors:', error);
      } finally {
        setLoadingFriends(false);
      }
    }
  };

  const fetchVendorRatingsAndReviews = async () => {
    const userId = vendor?.userId || currentUser?.uid;  // Use vendor ID or current user's ID if it's the vendor
    if (userId) {
      try {
        const vendorRef = doc(firestore, 'vendors', userId);  // Use correct userId
        const vendorSnap = await getDoc(vendorRef);
  
        if (vendorSnap.exists()) {
          const vendorData = vendorSnap.data();
          setAverageRating(vendorData.averageRating || 0);
          console.log('Fetched Average Rating:', vendorData.averageRating); // Check if average rating is fetched
  
          // Fetch reviews from Firestore
          const reviewsRef = collection(firestore, 'vendors', userId, 'reviews');
          const reviewsQuery = query(reviewsRef, orderBy('createdAt', 'desc'));
          const reviewsSnapshot = await getDocs(reviewsQuery);
  
          const reviewsList = await Promise.all(
            reviewsSnapshot.docs.map(async (reviewDoc) => {
              const reviewData = reviewDoc.data();
  
              // Fetch the user details based on userId in the review
              const userRef = doc(firestore, 'users', reviewData.userId);
              const userSnap = await getDoc(userRef);
  
              let userName = 'Unknown User';
              if (userSnap.exists()) {
                const userData = userSnap.data();
                userName = userData.name || userData.email || 'Unknown User';
              }
  
              return {
                ...reviewData,
                userName, // Attach the username to the review data
              };
            })
          );
          setReviews(reviewsList);
          console.log('Fetched Reviews:', reviewsList); // Check if reviews are fetched
        }
      } catch (error) {
        console.error('Error fetching vendor ratings and reviews:', error);
      }
    }
  };
  


  

  // Handle review submission
  const handleSubmitReview = async () => {
    if (!newRating) {
      Alert.alert('Rating required', 'Please provide a rating before submitting.');
      return;
    }

    if (currentUser && vendor) {
      try {
        const reviewsRef = collection(firestore, 'vendors', vendor.userId, 'reviews');
        await addDoc(reviewsRef, {
          userId: currentUser.uid,
          rating: newRating,
          reviewText: newReviewText || '',
          createdAt: new Date(),
        });

        // Recalculate average rating
        const reviewsSnapshot = await getDocs(reviewsRef);
        const totalReviews = reviewsSnapshot.size;
        const totalRating = reviewsSnapshot.docs.reduce((acc, doc) => acc + doc.data().rating, 0);
        const updatedAverageRating = totalRating / totalReviews;

        // Update vendor's average rating
        const vendorRef = doc(firestore, 'vendors', vendor.userId);
        await updateDoc(vendorRef, { averageRating: updatedAverageRating });

        // Reset the form
        setNewRating(0);
        setNewReviewText('');

        // Refresh the reviews list
        fetchVendorRatingsAndReviews();

      } catch (error) {
        console.error('Error submitting review:', error);
      }
    }
  };

  const handleAddToFavorites = async () => {
    if (currentUser && vendor) {
      try {
        const userRef = doc(firestore, 'users', currentUser.uid);
        if (isFavorited) {
          await updateDoc(userRef, {
            favorites: arrayRemove(vendor.userId),
          });
          setIsFavorited(false);
        } else {
          await updateDoc(userRef, {
            favorites: arrayUnion(vendor.userId),
          });
          setIsFavorited(true);
        }
      } catch (error) {
        console.error('Error updating favorites:', error);
      }
    }
  };

  const handleShareVendor = async () => {
    const emailToShare = selectedFriend ? selectedFriend : friendEmail.toLowerCase(); // Convert email to lowercase
    if (emailToShare && currentUser && vendor) {
      try {
        const usersRef = collection(firestore, 'users');
        const q = query(usersRef, where('email', '==', emailToShare.toLowerCase()));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const friendSnap = querySnapshot.docs[0];
          const vendorRef = doc(firestore, 'users', currentUser.uid);
          await updateDoc(vendorRef, {
            sharedList: arrayUnion({
              vendorId: vendor.userId,
              location: selectedLocation,
              sharedBy: emailToShare,
            }),
          });

          const friendRef = doc(firestore, 'users', friendSnap.id);
          await updateDoc(friendRef, {
            sharedList: arrayUnion({
              vendorId: vendor.userId,
              location: selectedLocation,
              sharedBy: currentUser.email?.toLowerCase(),
            }),
          });

          setShareModalVisible(false);
          setFriendEmail('');
          setSelectedFriend(null);
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error sharing vendor:', error);
      }
    }
  };

  const handlePostImagePicker = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 1,
    });

    if (!result.canceled) {
      setNewPostImage(result.assets[0].uri);  
    }
  };

  const handleSavePost = async () => {
    if (newPostImage && currentUser) {
      try {
        const storageRef = ref(storage, `vendorPosts/${currentUser.uid}/${Date.now()}`);
        const response = await fetch(newPostImage);
        const blob = await response.blob();
        await uploadBytes(storageRef, blob); 
        const downloadUrl = await getDownloadURL(storageRef); 

        const vendorRef = doc(firestore, 'vendors', currentUser.uid);
        const vendorSnap = await getDoc(vendorRef);
        const vendorData = vendorSnap.data();

        const imageAspect = await new Promise<number>((resolve, reject) => {
          Image.getSize(newPostImage, (width, height) => {
            resolve(height / width);
          }, reject);
        });

        const updatedPosts = [
          ...(vendorData?.posts || []),
          {
            image: downloadUrl,
            caption: newPostCaption,
            price: newPostPrice,
            aspectRatio: imageAspect, 
            timestamp: Date.now(),
          },
        ];
        
        await updateDoc(vendorRef, { posts: updatedPosts });
        setPosts(updatedPosts);

        setNewPostModalVisible(false);
        setNewPostImage(null);
        setNewPostCaption('');
        setNewPostPrice(''); 
      } catch (error) {
        setErrorMessage('Error saving post');
      }
    } else {
      setErrorMessage('Please upload an image');
    }
  };

  const handleDeletePost = (post: any) => {
    confirmDelete(`the post "${post.caption}"`, async () => {
      if (currentUser) {
        try {
          const storageRef = ref(storage, post.image);
          await deleteObject(storageRef);

          const vendorRef = doc(firestore, 'vendors', currentUser.uid);
          const updatedPosts = posts.filter((p) => p.image !== post.image);
          await updateDoc(vendorRef, { posts: updatedPosts });

          setPosts(updatedPosts);
        } catch (error) {
          console.error('Error deleting post:', error);
        }
      }
    });
  };

  const handlePostClick = (post: any) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const getGridColumns = () => {
    const screenWidth = Dimensions.get('window').width;
    return screenWidth > 768 ? 4 : 2;
  };

  const goBackOrClose = () => {
    if (isExplorer) {
      onClose?.();
    } else {
      navigation?.goBack();
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#FFB6C1" />
      </View>
    );
  }

  const StarRatingComponent = ({ rating, onRatingSelect, interactive = false, starSize = 30 }) => {
    const stars = [1, 2, 3, 4, 5];
    return (
      <View style={styles.starContainer}>
        {stars.map((star) => (
          <TouchableOpacity
            key={star}
            onPress={() => interactive && onRatingSelect(star)}
            disabled={!interactive}
          >
            <Ionicons
              name={star <= rating ? 'star' : 'star-outline'}
              size={starSize}  // Adjust the star size dynamically
              color={star <= rating ? '#FFB6C1' : '#FFB6C1'}
            />
          </TouchableOpacity>
        ))}
      </View>
    );
  };
  
  const renderReviewsCarousel = () => {
    const latestReviews = reviews.slice(0, 5); // Get the latest 5 reviews

    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.carouselContainer}>
        {latestReviews.map((review, index) => (
          <View key={index} style={styles.reviewCard}>
            <Text style={styles.reviewerName}>{review.userName}</Text>
            <StarRatingComponent rating={review.rating} starSize={18} />
            <Text style={styles.reviewText}>{review.reviewText || 'No review text'}</Text>
          </View>
        ))}
        
        {reviews.length > 5 && (
          <TouchableOpacity style={styles.seeAllButton} onPress={() => setShowAllReviewsModal(true)}>
            <Text style={styles.seeAllText}>See All</Text>
          </TouchableOpacity>
        )}
      </ScrollView>
    );
  };



  return (
    <View style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={styles.scrollContainer} style={styles.scrollView}>
        <View style={styles.profileContainer}>
          <TouchableOpacity style={styles.topCloseButton} onPress={goBackOrClose}>
            <Ionicons name={isExplorer ? "close" : "arrow-back"} size={30} color="#4A4A4A" />
          </TouchableOpacity>

        
        <View style={styles.profileHeader}>
          {profilePicture ? (
            <Image source={{ uri: profilePicture }} style={styles.profileImage} resizeMode="contain" />
          ) : (
            <Ionicons name="camera" size={100} color="#FFB6C1" />
          )}
        </View>
      

          <Text style={styles.vendorName}>{vendorName}</Text>
          <Text style={styles.description}>{description}</Text>

          <View style={styles.infoRow}>
            <Ionicons name="location-outline" size={20} color="#4A4A4A" />
            <Text style={styles.infoText}>{selectedLocation}</Text>
          </View>

          <View style={styles.infoRow}>
            <Ionicons name="pricetags-outline" size={20} color="#4A4A4A" />
            <Text style={styles.infoText}>{selectedCategory}</Text>
          </View>

          {/* Vendor Average Rating */}
                <StarRatingComponent rating={averageRating} starSize={22}  />

            {/* Button to show modal */}
            <TouchableOpacity onPress={() => setShowRatingReviewModal(true)}>
            <Text style={styles.linkText}>Go to Ratings and Reviews</Text>
          </TouchableOpacity>

         {/* Ratings and Reviews Modal */}
<Modal
  visible={showRatingReviewModal}
  transparent={true}
  animationType="fade"
  onRequestClose={() => setShowRatingReviewModal(false)}
>
  <View style={styles.modalOverlay}>
    <View style={styles.modalContainer}>
      <TouchableOpacity style={styles.topCloseButton} onPress={() => setShowRatingReviewModal(false)}>
        <Ionicons name="close" size={24} color="#333" />
      </TouchableOpacity>

      {/* Vendor Average Rating */}
      <Text style={styles.modalTitle}>Average Rating</Text>
      <StarRatingComponent rating={averageRating} />

      {/* Latest Reviews Carousel */}
      {reviews.length > 0 ? (
        <View>
          <Text style={styles.reviewTitle}>Latest Reviews</Text>
          <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.carouselContainer}>
            {reviews.slice(0, 5).map((review, index) => (
              <View key={index} style={styles.reviewCard}>
                <Text style={styles.reviewerName}>{review.userName}</Text>
                <StarRatingComponent rating={review.rating} starSize={18} />
                <Text style={styles.reviewText}>{review.reviewText || 'No review text'}</Text>
              </View>
            ))}
            
            {reviews.length > 5 && (
              <TouchableOpacity style={styles.seeAllButton} onPress={() => setShowAllReviewsModal(true)}>
                <Text style={styles.seeAllText}>See All</Text>
              </TouchableOpacity>
            )}
          </ScrollView>
        </View>
      ) : (
        <Text style={styles.noReviewsText}>No reviews yet.</Text>
      )}

      {/* Only explorers can leave reviews */}
      {isExplorer && (
        <View style={styles.reviewForm}>
          <Text style={styles.reviewFormTitle}>Leave a Rating and Review</Text>
          <StarRatingComponent rating={newRating} onRatingSelect={setNewRating} interactive={true} />
          <TextInput
            style={styles.input}
             placeholderTextColor="#666"
            placeholder="Leave a review (optional)"
            value={newReviewText}
            onChangeText={setNewReviewText}
          />
          <TouchableOpacity style={styles.submitButton} onPress={handleSubmitReview}>
            <Text style={styles.submitButtonText}>Submit</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  </View>
</Modal>

{/* Modal for showing all reviews */}
<Modal
  visible={showAllReviewsModal}
  transparent={true}
  animationType="slide"
  onRequestClose={() => setShowAllReviewsModal(false)}
>
  <View style={styles.modalOverlay}>
    <View style={styles.modalContainer}>
      <TouchableOpacity style={styles.topCloseButton} onPress={() => setShowAllReviewsModal(false)}>
        <Ionicons name="close" size={24} color="#333" />
      </TouchableOpacity>

      <Text style={styles.modalTitle}>All Reviews</Text>
      <ScrollView style={styles.allReviewsContainer}>
        {reviews.map((review, index) => (
          <View key={index} style={styles.reviewCard}>
            <Text style={styles.reviewerName}>{review.userName}</Text>
            <StarRatingComponent rating={review.rating} starSize={18} />
            <Text style={styles.reviewText}>{review.reviewText || 'No review text'}</Text>
          </View>
        ))}
      </ScrollView>
    </View>
  </View>
</Modal>


          {/* Add to Shared List - Explorer Only */}
          {isExplorer && (
            <>
              <View style={styles.buttonRow}>
    {/* Favorite Button */}
    <TouchableOpacity style={styles.favoriteButton} onPress={handleAddToFavorites}>
      <Ionicons
        name={isFavorited ? 'heart' : 'heart-outline'}
        size={32}
        color={isFavorited ? '#FFB6C1' : '#FFB6C1'} // 
      />
    </TouchableOpacity>

    {/* Shared List Button */}
    <TouchableOpacity style={styles.sharedListButton} onPress={() => setShareModalVisible(true)}>
      <Text style={styles.buttonText}>Add to Shared List</Text>
    </TouchableOpacity>
  </View>

              {/* Modal for sharing */}
              <Modal visible={shareModalVisible} transparent={true} animationType="slide">
                <View style={styles.modalOverlay}>
                  <View style={styles.modalContainer}>
                    <Text style={styles.modalTitle}>Share with a Friend</Text>

                    {/* Show existing friends as buttons */}
                    {!loadingFriends && friendsList.length > 0 && (
                      <>
                        <Text style={styles.friendsListTitle}>Share with existing friends:</Text>
                        {friendsList.map((friend, index) => (
                          <TouchableOpacity
                            key={index}
                            style={[
                              styles.friendButton,
                              selectedFriend === friend ? styles.selectedFriendButton : null,
                            ]}
                            onPress={() => setSelectedFriend(friend)}
                          >
                            <Text style={styles.friendButtonText}>{friend}</Text>
                          </TouchableOpacity>
                        ))}
                        <Text style={styles.orText}>or</Text>
                      </>
                    )}

                    {/* Email Input for sharing */}
                    <TextInput
                      placeholder="Enter friend's email"
                      placeholderTextColor="#666"
                      style={styles.input}
                      value={friendEmail}
                      onChangeText={setFriendEmail}
                      editable={!selectedFriend} 
                    />

                    <TouchableOpacity style={styles.saveButton} onPress={handleShareVendor}>
                      <Text style={styles.saveButtonText}>Share</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.modalButton} onPress={() => setShareModalVisible(false)}>
                      <Text style={styles.buttonText}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
            </>
          )}

          {googleMapsLocation && (
            <TouchableOpacity
              style={styles.mapsButton}
              onPress={() => Linking.openURL(googleMapsLocation)}
            >
              <Text style={styles.mapsButtonText}>View on Google Maps</Text>
              <Ionicons name="map-outline" size={24} color="#333" />
            </TouchableOpacity>
          )}

          {!isExplorer && (
            <>
              <TouchableOpacity style={styles.button} onPress={() => navigation?.navigate('EditVendorProfile')}>
                <Text style={styles.buttonText}>Edit Profile</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => navigation?.navigate('RequestAd')}>
        <Text style={styles.buttonText}>Advertise or Promote your profile</Text>
      </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => setNewPostModalVisible(true)}>
                <Text style={styles.buttonText}>New Post</Text>
              </TouchableOpacity>
            </>
          )}

          <View style={styles.postsGrid}>
            {posts.map((post, index) => (
              <View key={index} style={[styles.postCard, { width: `${100 / getGridColumns() - 2}%` }]}>
                <TouchableOpacity onPress={() => handlePostClick(post)}>
                <Image
  source={{ uri: post.image }}
  style={[
    styles.postImage,
    {
      width: '100%',
      height: Math.min(
        Dimensions.get('window').width / (post.aspectRatio || 1),
        300 // Set a max height to reduce zoom, adjust this value as needed
      ),
    },
  ]}
  resizeMode="cover"
/>

                </TouchableOpacity>
                <View style={styles.postInfo}>
                  {post.caption && <Text style={styles.postCaption}>{post.caption}</Text>}
                  {post.price && (
                    <Text style={styles.postPrice}>
                      {getCurrencySymbolForLocation(selectedLocation)} {post.price}
                    </Text>
                  )}
                </View>
                {!isExplorer && (
                  <TouchableOpacity style={styles.deleteButton} onPress={() => handleDeletePost(post)}>
                    <Text style={styles.deleteButtonText}>Delete</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </View>
        </View>

        {/* Modal for New Post */}
        <Modal transparent={true} visible={newPostModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Create New Post</Text>

              <TouchableOpacity onPress={handlePostImagePicker} style={styles.newPostImagePicker}>
                {newPostImage ? (
                  <Image source={{ uri: newPostImage }} style={styles.newPostImagePreview} />
                ) : (
                  <Ionicons name="camera" size={100} color="#FFB6C1" />
                )}
              </TouchableOpacity>

              <TextInput
                placeholder="Enter a caption (optional)"
                placeholderTextColor="#666"
                style={styles.input}
                value={newPostCaption}
                onChangeText={setNewPostCaption}
              />

              <TextInput
                placeholder="Enter price (optional)"
                placeholderTextColor="#666"
                style={styles.input}
                value={newPostPrice}
                keyboardType="numeric"
                onChangeText={setNewPostPrice}
              />

              <TouchableOpacity style={styles.saveButton} onPress={handleSavePost}>
                <Text style={styles.saveButtonText}>Post</Text>
              </TouchableOpacity>

              <TouchableOpacity style={styles.button} onPress={() => setNewPostModalVisible(false)}>
                <Text style={styles.buttonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/* Modal to display full post */}
        {selectedPost && (
          <Modal transparent={true} visible={!!selectedPost} animationType="slide">
            <View style={styles.modalOverlay}>
              <View style={styles.modalContainer}>
                <Image source={{ uri: selectedPost.image }} style={styles.fullImage} resizeMode="contain" />
                {selectedPost.caption && <Text style={styles.fullCaption}>{selectedPost.caption}</Text>}
                {selectedPost.price && (
                  <Text style={styles.fullPrice}>
                    {getCurrencySymbolForLocation(selectedLocation)} {selectedPost.price}
                  </Text>
                )}
                <TouchableOpacity style={styles.topCloseButton} onPress={closeModal}>
                <Ionicons name="close" size={24} color="#333" />
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    backgroundColor: '#fff',
    paddingBottom: 100,
    flexGrow: 1,
  },
  scrollView: {
    height: '100vh',
  },
  profileContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    paddingTop: 100,
    paddingBottom: 50,
    minHeight: '100%',
  },
  profileHeader: {
    backgroundColor: '#fff',
    borderRadius: 100,
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  profileImage: {
    width: 150,
    height: 150,
    borderRadius: 100,
  },
  vendorName: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginBottom: 10,
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    color: '#666',
    marginBottom: 20,
    textAlign: 'center',
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  infoText: {
    fontSize: 18,
    color: '#4A4A4A',
    marginLeft: 10,
  },
  button: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 12,
    paddingHorizontal: 30,
    borderRadius: 15,
    alignItems: 'center',
    marginVertical: 10,
    width: '80%',
  },
  buttonText: {
    color: '#333',
    fontWeight: '700',
    fontSize: 15,
  },
  topCloseButton: {
    position: 'absolute',
    top: 40,
    left: 20,
    zIndex: 10, 
  },
  postsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  postCard: {
    backgroundColor: '#fff',
    borderRadius: 15,
    overflow: 'hidden',
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  postImage: {
    width: '100%',
    borderRadius: 10,
  },
  postInfo: {
    padding: 10,
    alignItems: 'center',
  },
  postCaption: {
    fontSize: 14,
    color: '#4A4A4A',
    textAlign: 'center',
  },
  postPrice: {
    fontSize: 16,
    color: '#93C572',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 5,
  },
  deleteButton: {
    backgroundColor: '#FF5C5C',
    paddingVertical: 6, // Reduced padding for smaller button size
    paddingHorizontal: 15, // Adjusted padding to make it more compact
    borderRadius: 8, // Rounded corners
    marginTop: 5,
    width: '40%', // Adjusted width to fit better within the layout
    alignSelf: 'right', // Center the button
  },
  deleteButtonText: {
    color: '#333',
    fontWeight: '600', // Slightly reduced font weight
    fontSize: 14, // Reduced font size for a cleaner look
    textAlign: 'left',
  },
  newPostImagePicker: {
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF0F6',
    borderWidth: 2,
    borderColor: '#FFB6C1',
    borderRadius: 15,
    marginBottom: 15,
  },
  newPostImagePreview: {
    width: '100%',
    height: '100%',
    borderRadius: 15,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    borderRadius: 20,
    padding: 20,
    marginHorizontal: 10,
    maxHeight: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    height: 50,
    width: '90%',
    backgroundColor: '#fff',
    borderColor: '#FFB6C1',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    marginBottom: 20,
    textAlign: 'center',
  },
  saveButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginBottom: 20,
    width: '90%',
  },
  saveButtonText: {
    color: '#333',
    fontWeight: '600',
  },
  fullImage: {
    width: '100%',
    height: 300,
    marginBottom: 15,
  },
  fullCaption: {
    fontSize: 18,
    color: '#4A4A4A',
    marginVertical: 10,
    textAlign: 'center',
  },
  fullPrice: {
    fontSize: 18,
    color: '#93C572',
    fontWeight: 'bold',
  },
  modalButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
  },
  mapsButton: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: '#333',
    marginVertical: 10,
    width: '60%',
    marginBottom: 25,
  },
  mapsButtonText: {
    color: '#333',
    fontWeight: '700',
    fontSize: 18,
    marginRight: 10,
  },
  friendsListTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
  },
  friendButton: {
    backgroundColor: '#FFF0F6',
    padding: 10,
    borderRadius: 15,
    marginBottom: 5,
  },
  selectedFriendButton: {
    borderWidth: 2,
    borderColor: '#FFB6C1',
  },
  friendButtonText: {
    color: '#4A4A4A',
    fontWeight: '600',
  },
  orText: {
    marginVertical: 10,
    textAlign: 'center',
    color: '#666',
  },
  latestReview: {
    marginVertical: 20,
  },
  reviewTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5,
  },
  reviewText: {
    fontSize: 16,
    color: '#666',
    marginTop: 5,
  },
  noReviewsText: {
    fontSize: 16,
    color: '#666',
    marginTop: 20,
  },
  reviewForm: {
    marginTop: 30,
    alignItems: 'center',
  },
  reviewFormTitle: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 15,
  },
  submitButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderRadius: 15,
    alignItems: 'center',
    width: '90%',
  },
  submitButtonText: {
    color: '#333',
    fontWeight: '600',
  },
  starContainer: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  linkText: {
    color: '#666',
    textDecorationLine: 'underline',
  },
  carouselContainer: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  reviewCard: {
    width: 200,
    padding: 10,
    marginRight: 10,
    backgroundColor: '#FFF0F6',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    height: 120,  
  },
  seeAllButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#FFB6C1',
    borderRadius: 15,
  },
  seeAllText: {
    color: '#FFF',
    fontWeight: '600',
  },
  allReviewsContainer: {
    paddingHorizontal: 10,
  },
  favoriteButton: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRow: {
    flexDirection: 'row',  // Align items horizontally
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
  sharedListButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 15,
    alignItems: 'center',
  },
});
