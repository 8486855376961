import React from 'react';
import { View, Text, StyleSheet, Image, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

type VendorCardProps = {
  vendor: {
    vendorName: string;
    location: string;
    profilePicture?: string;
    averageRating?: number;  // Add averageRating to the vendor prop
  };
};

export default function VendorCard({ vendor }: VendorCardProps) {
  const renderStars = (rating: number) => {
    const stars = [1, 2, 3, 4, 5];
    return (
      <View style={styles.starContainer}>
        {stars.map((star) => (
          <Ionicons
            key={star}
            name={star <= rating ? 'star' : 'star-outline'}
            size={16}  // Adjust star size
            color="#FFB6C1"
          />
        ))}
      </View>
    );
  };

  return (
    <View style={styles.cardContainer}>
      {vendor.profilePicture ? (
        <Image source={{ uri: vendor.profilePicture }} style={styles.profileImage} resizeMode="contain" />
      ) : (
        <Ionicons name="person-circle-outline" size={Platform.OS === 'web' ? 120 : 80} color="#FFB6C1" />
      )}
      <Text style={styles.vendorName}>{vendor.vendorName}</Text>
      <View style={styles.infoRow}>
        <Ionicons name="location-outline" size={Platform.OS === 'web' ? 24 : 20} color="#4A4A4A" />
        <Text style={styles.infoText}>{vendor.location}</Text>
      </View>

      {/* Display average rating if available, otherwise show "No ratings yet" */}
      {vendor.averageRating !== undefined && vendor.averageRating > 0 ? (
        <View style={styles.ratingRow}>
          {renderStars(Math.round(vendor.averageRating))}
          <Text style={styles.ratingText}>{vendor.averageRating.toFixed(1)}</Text>
        </View>
      ) : (
        <Text style={styles.noRatingText}>No ratings yet</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: '#FFF',
    padding: Platform.OS === 'web' ? 20 : 15,
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#FFB6C1',
    width: Platform.OS === 'web' ? 320 : 140,
  },
  profileImage: {
    width: Platform.OS === 'web' ? 160 : 80,
    height: Platform.OS === 'web' ? 160 : 80,
    borderRadius: Platform.OS === 'web' ? 80 : 40,
    marginBottom: 10,
  },
  vendorName: {
    fontSize: Platform.OS === 'web' ? 24 : 16,
    fontWeight: '600',
    color: '#4A4A4A',
    textAlign: 'center',
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  infoText: {
    fontSize: Platform.OS === 'web' ? 18 : 14,
    color: '#4A4A4A',
    marginLeft: 5,
  },
  ratingRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  starContainer: {
    flexDirection: 'row',
    marginRight: 5,
  },
  ratingText: {
    fontSize: Platform.OS === 'web' ? 14 : 14,
    color: '#4A4A4A',
  },
  noRatingText: {
    fontSize: Platform.OS === 'web' ? 16 : 14,
    color: '#999',
    marginTop: 8,
  },
});
