import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, initializeAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

const firebaseConfig = {
  apiKey: "AIzaSyCfy1YNVvrMzD1EANZ820BiSQbClvYOxKI",
  authDomain: "aweyy.com",
  projectId: "aweyy-bdbf6",
  storageBucket: "aweyy-bdbf6.appspot.com",
  messagingSenderId: "893495440342",
  appId: Platform.OS === 'ios'
    ? "1:893495440342:ios:a9a0b0108d82b0ac062a77"
    : "1:893495440342:android:04bf4f80b16c0b9f062a77",
  measurementId: "G-SSNRCQC6ZX",
};

// Initialize Firebase app
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase services with platform-based conditional logic
let auth;

if (Platform.OS === 'ios' || Platform.OS === 'android') {
  // Only attempt to use getReactNativePersistence on native platforms
  const { getReactNativePersistence } = require('firebase/auth'); // Import here to avoid web load issues
  auth = initializeAuth(app, { persistence: getReactNativePersistence(AsyncStorage) });
} else {
  auth = getAuth(app);
}

const firestore = getFirestore(app);
const storage = getStorage(app);

// Conditionally initialize analytics for web
let analytics;
isAnalyticsSupported().then((supported) => {
  if (supported && Platform.OS === 'web') {
    analytics = getAnalytics(app);
  }
});

export { auth, firestore, storage, analytics };
