import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  SafeAreaView,
  Alert,
  Modal,
  FlatList,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { firestore } from '../../firebaseConfig';
import { collection, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { useNavigation } from '@react-navigation/native';

const categories = ['Accommodation', 'Beauty', 'Books', 'Food', 'Jewelry', 'Tattoos', 'Thrift Shops'];

const RecommendationsScreen: React.FC = () => {
  const navigation = useNavigation();
  const [placeName, setPlaceName] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [details, setDetails] = useState('');
  const [instagram, setInstagram] = useState('');
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleRecommendation = async () => {
    if (!placeName || !location || !category || !details) {
      Alert.alert("Error", "Please fill out all required fields.");
      return;
    }

    // Convert inputs to lowercase for consistency
    const placeNameLower = placeName.toLowerCase();
    const locationLower = location.toLowerCase();
    const categoryLower = category.toLowerCase();

    try {
      const recommendationsRef = doc(
        collection(firestore, 'recommendations'), 
        `${placeNameLower}_${locationLower}_${categoryLower}`
      );

      const docSnap = await getDoc(recommendationsRef);

      if (docSnap.exists()) {
        await updateDoc(recommendationsRef, {
          recommendationCount: increment(1),
        });
      } else {
        await setDoc(recommendationsRef, {
          placeName: placeNameLower,
          location: locationLower,
          category: categoryLower,
          details,
          instagram: instagram || null,
          recommendationCount: 1,
          createdAt: new Date().toISOString(),
        });
      }

      if (Platform.OS === 'web') {
        setSuccessMessage('Thank you for your recommendation!');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        Alert.alert("Success", "Thank you for your recommendation!");
      }

      // Clear input fields
      setPlaceName('');
      setLocation('');
      setCategory('');
      setDetails('');
      setInstagram('');
    } catch (error) {
      Alert.alert("Error", "An error occurred while submitting your recommendation. Please try again.");
      console.error("Error submitting recommendation:", error);
    }
  };

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <LinearGradient colors={['#FFDEE9', '#ffffff']} style={styles.background}>
        
        {/* Back Button */}
        <SafeAreaView>
          <View style={styles.backButtonContainer}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Ionicons name="arrow-back" size={24} color="black" />
            </TouchableOpacity>
          </View>
        </SafeAreaView>

        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : undefined} 
          style={styles.keyboardView}
        >
          <ScrollView contentContainerStyle={styles.scrollContainer} keyboardShouldPersistTaps="handled">
            <Text style={styles.title}>Recommend a Place</Text>

            <TextInput
              placeholder="Name of the recommended place"
              style={styles.input}
              value={placeName}
              onChangeText={setPlaceName}
            />
            <TextInput
              placeholder="Location (e.g. Bali, London etc.)"
              style={styles.input}
              value={location}
              onChangeText={setLocation}
            />

            {/* Category Selector */}
            <TouchableOpacity style={styles.input} onPress={() => setCategoryModalVisible(true)}>
              <Text style={styles.dropdownButtonText}>
                {category ? `Category: ${category}` : 'Select a Category'}
              </Text>
              <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
            </TouchableOpacity>

            <TextInput
              placeholder="Details about the place"
              style={styles.input}
              value={details}
              onChangeText={setDetails}
              multiline
            />
            <TextInput
              placeholder="Instagram/Website (optional)"
              style={styles.input}
              value={instagram}
              onChangeText={setInstagram}
            />

            <TouchableOpacity style={styles.submitButton} onPress={handleRecommendation}>
              <Text style={styles.submitButtonText}>Recommend</Text>
            </TouchableOpacity>

            {/* Success message display for web */}
            {Platform.OS === 'web' && successMessage ? (
              <Text style={styles.successMessage}>{successMessage}</Text>
            ) : null}
          </ScrollView>
        </KeyboardAvoidingView>

        {/* Category Modal */}
        <Modal transparent={true} visible={isCategoryModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Select Category</Text>
              <FlatList
                data={categories}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setCategory(item);
                      setCategoryModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setCategoryModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
    paddingHorizontal: 20,
  },
  backButtonContainer: {
    paddingTop: 20,
    paddingLeft: 10,
  },
  keyboardView: {
    flex: 1,
  },
  scrollContainer: {
    alignItems: 'center',
    paddingBottom: 20,
  },
  title: {
    fontSize: 32,
    fontWeight: '800',
    color: '#4A4A4A',
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    height: 50,
    backgroundColor: '#fff',
    borderColor: '#FFB6C1',
    borderWidth: 2,
    borderRadius: 25,
    paddingHorizontal: 15,
    marginBottom: 15,
    width: 300,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '600',
    fontSize: 14,
    color: '#a4a4a4',
  },
  dropdownButtonText: {
    fontSize: 14,
    color: '#a4a4a4',
    fontWeight: '500',
  },
  iconSpacing: {
    marginLeft: 5,
  },
  submitButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
    alignItems: 'center',
  },
  submitButtonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
  successMessage: {
    fontSize: 16,
    color: 'green',
    fontWeight: '600',
    marginTop: 10,
    textAlign: 'center',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    marginHorizontal: 20,
    borderRadius: 15,
    padding: 20,
    maxHeight: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
  },
  categoryCard: {
    backgroundColor: '#FFF0F6',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
    width: 250,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#FFB6C1',
  },
  categoryText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
  closeModalButton: {
    marginTop: 20,
    backgroundColor: '#FFB6C1',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 15,
  },
  closeModalText: {
    fontSize: 16,
    color: '#fff',
    fontWeight: '600',
  },
});

export default RecommendationsScreen;
