import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  SafeAreaView,
  Alert,
  Modal,
  FlatList,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { firestore, storage, auth } from '../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DateTimePicker from '@react-native-community/datetimepicker';
import { useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';

const adTypes = ['Banner Ad', 'Promote Profile'];
const durations = ['24 hours', '1 week', '2 weeks', '3 weeks', '4 weeks'];
const bannerImageOptions = ['Upload your own banner image', 'Have our team create a banner image'];

const RequestAdScreen: React.FC = () => {
  const navigation = useNavigation();
  const [adType, setAdType] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [isAdTypeModalVisible, setAdTypeModalVisible] = useState(false);
  const [isDurationModalVisible, setDurationModalVisible] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isBannerImageModalVisible, setBannerImageModalVisible] = useState(false);
  const [bannerImageOption, setBannerImageOption] = useState('');
  const [bannerImage, setBannerImage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleImagePicker = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.5,
      });

      if (!result.canceled && result.assets && result.assets[0].uri) {
        setUploading(true);
        const imageUri = result.assets[0].uri;

        const response = await fetch(imageUri);
        const blob = await response.blob();

        const imageRef = ref(storage, `bannerImages/${auth.currentUser?.uid}/${Date.now()}-banner.jpg`);
        await uploadBytes(imageRef, blob);
        const downloadUrl = await getDownloadURL(imageRef);
        setBannerImage(downloadUrl);

        Alert.alert("Success", "Image uploaded successfully.");
      } else {
        Alert.alert("Error", "Image selection was canceled.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Alert.alert("Error", "Failed to upload image. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async () => {
    if (!adType || !email || !startDate || !duration || !description || (adType === 'Banner Ad' && !bannerImageOption)) {
      Alert.alert("Error", "Please fill out all fields.");
      return;
    }

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        Alert.alert("Error", "User not authenticated. Please log in again.");
        return;
      }

      const vendorId = currentUser.uid;
      const vendorName = currentUser.displayName || "Unknown Vendor";

      await addDoc(collection(firestore, 'adRequests'), {
        adType,
        email,
        startDate: startDate.toISOString(),
        duration,
        description,
        bannerImageOption,
        bannerImage: bannerImageOption === 'Upload your own banner image' ? bannerImage : null,
        requestedAt: new Date().toISOString(),
        vendorId,
        vendorName,
      });

      Alert.alert("Success", "Your ad request has been submitted!");
      navigation.goBack();
    } catch (error) {
      Alert.alert("Error", "Failed to submit the ad request. Try again.");
      console.error("Error adding ad request:", error);
    }
  };

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <LinearGradient colors={['#FFDEE9', '#ffffff']} style={styles.background}>
        <SafeAreaView>
          <View style={styles.backButtonContainer}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Ionicons name="arrow-back" size={24} color="black" />
            </TouchableOpacity>
          </View>
        </SafeAreaView>

        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={styles.keyboardView}>
          <ScrollView contentContainerStyle={styles.scrollContainer} keyboardShouldPersistTaps="handled">
            <Text style={styles.title}>Request an Ad or Promotion</Text>

            <TouchableOpacity style={styles.input} onPress={() => setAdTypeModalVisible(true)}>
              <Text style={styles.dropdownButtonText}>
                {adType ? `Type: ${adType}` : 'Select Ad Type'}
              </Text>
              <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
            </TouchableOpacity>

            <TouchableOpacity style={styles.input} onPress={() => setShowDatePicker(true)}>
              <Text style={styles.dropdownButtonText}>
                {startDate ? `Start Date: ${startDate.toDateString()}` : 'Select Start Date'}
              </Text>
              <Ionicons name="calendar" size={22} color="#333" style={styles.iconSpacing} />
            </TouchableOpacity>

            {showDatePicker && (
              <DateTimePicker
                value={startDate || new Date()}
                mode="date"
                display="default"
                onChange={(event, date) => {
                  setShowDatePicker(false);
                  if (date) setStartDate(date);
                }}
              />
            )}

            <TouchableOpacity style={styles.input} onPress={() => setDurationModalVisible(true)}>
              <Text style={styles.dropdownButtonText}>
                {duration ? `Duration: ${duration}` : 'Select Duration'}
              </Text>
              <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
            </TouchableOpacity>

            <TextInput
              placeholder="Your Email"
              style={styles.input}
              value={email}
              onChangeText={setEmail}
              keyboardType="email-address"
            />

            <TextInput
              placeholder="Description (details of your request)"
              style={styles.input}
              value={description}
              onChangeText={setDescription}
              multiline
            />

            {adType === 'Banner Ad' && (
              <>
                <TouchableOpacity style={styles.input} onPress={() => setBannerImageModalVisible(true)}>
                  <Text style={styles.dropdownButtonText}>
                    {bannerImageOption ? `Banner Image: ${bannerImageOption}` : 'Upload or Request a Banner'}
                  </Text>
                  <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
                </TouchableOpacity>

                {bannerImageOption === 'Upload your own banner image' && (
                  <TouchableOpacity style={styles.imageUploadButton} onPress={handleImagePicker}>
                    <Text style={styles.uploadText}>
                      {bannerImage ? 'Image Uploaded' : 'Choose Image'}
                    </Text>
                  </TouchableOpacity>
                )}
              </>
            )}

            {uploading && <ActivityIndicator size="large" color="#FFB6C1" />}

            <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
              <Text style={styles.submitButtonText}>Submit Request</Text>
            </TouchableOpacity>
          </ScrollView>
        </KeyboardAvoidingView>

        <Modal transparent={true} visible={isAdTypeModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Select Ad Type</Text>
              <FlatList
                data={adTypes}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setAdType(item);
                      setAdTypeModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setAdTypeModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        <Modal transparent={true} visible={isDurationModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Select Duration</Text>
              <FlatList
                data={durations}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setDuration(item);
                      setDurationModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setDurationModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        <Modal transparent={true} visible={isBannerImageModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Banner Image Option</Text>
              <FlatList
                data={bannerImageOptions}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setBannerImageOption(item);
                      setBannerImageModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setBannerImageModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  background: { flex: 1, paddingHorizontal: 20 },
  backButtonContainer: { paddingTop: 20, paddingLeft: 10 },
  keyboardView: { flex: 1 },
  scrollContainer: { alignItems: 'center', paddingBottom: 20 },
  title: { fontSize: 32, fontWeight: '800', color: '#4A4A4A', marginBottom: 20, textAlign: 'center' },
  input: {
    height: 50,
    backgroundColor: '#fff',
    borderColor: '#FFB6C1',
    borderWidth: 2,
    borderRadius: 25,
    paddingHorizontal: 15,
    marginBottom: 15,
    width: 300,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '600',
    fontSize: 14,
    color: '#a4a4a4',
  },
  dropdownButtonText: { fontSize: 14, color: '#a4a4a4', fontWeight: '500' },
  iconSpacing: { marginLeft: 5 },
  submitButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
    alignItems: 'center',
  },
  submitButtonText: { fontSize: 18, color: '#FFF', fontWeight: '600' },
  imageUploadButton: {
    height: 50,
    backgroundColor: '#FFF0F6',
    borderColor: '#FFB6C1',
    borderWidth: 2,
    borderRadius: 25,
    paddingHorizontal: 15,
    marginBottom: 15,
    width: 300,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadText: { fontSize: 14, color: '#a4a4a4', fontWeight: '500' },
  modalOverlay: { flex: 1, justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)' },
  modalContainer: { backgroundColor: '#FFF', marginHorizontal: 20, borderRadius: 15, padding: 20, alignItems: 'center' },
  modalTitle: { fontSize: 24, fontWeight: '700', marginBottom: 20 },
  categoryCard: {
    backgroundColor: '#FFF0F6',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
    width: 250,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#FFB6C1',
  },
  categoryText: { fontSize: 18, color: '#333', fontWeight: '600' },
  closeModalButton: { marginTop: 20, backgroundColor: '#FFB6C1', paddingVertical: 10, paddingHorizontal: 30, borderRadius: 15 },
  closeModalText: { fontSize: 16, color: '#fff', fontWeight: '600' },
});

export default RequestAdScreen;
