import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  Alert,
  Platform,
} from 'react-native';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signInWithCredential } from 'firebase/auth';
import { LinearGradient } from 'expo-linear-gradient';
import * as Google from 'expo-auth-session/providers/google';
import { auth, firestore } from '../../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';
import * as WebBrowser from 'expo-web-browser';

WebBrowser.maybeCompleteAuthSession();

type SignupScreenProps = {
  navigation: StackNavigationProp<RootStackParamList, 'SignUpScreen'>;
};

export default function SignupScreen({ navigation }: SignupScreenProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [request, response, promptAsync] = Google.useAuthRequest({
    clientId: '893495440342-n7f9hc4qpvg01pbca34fob1sh61rqqek.apps.googleusercontent.com',
    iosClientId: '893495440342-o6vtfdlvv9s4q21fvid8sgahqahb0q18.apps.googleusercontent.com',
    androidClientId: '893495440342-hm95n7cll0egfo7v6nqe2068tra00la6.apps.googleusercontent.com',
    webClientId: '893495440342-n7f9hc4qpvg01pbca34fob1sh61rqqek.apps.googleusercontent.com',
    redirectUri: 'https://aweyy.com/__/auth/handler',
  });

  useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params;
      const credential = GoogleAuthProvider.credential(id_token);
      signInWithCredential(auth, credential)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const userRef = doc(firestore, 'users', user.uid);
          await setDoc(userRef, {
            name: user.displayName || 'User',
            email: user.email,
            role: 'explorer',
            createdAt: new Date().toISOString(),
          });
          navigation.replace('LocationSelector');
        })
        .catch((error) => setErrorMessage(error.message));
    }
  }, [response]);

  const handleSignup = async () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }
    if (name.trim() === '') {
      setErrorMessage('Please enter your name');
      return;
    }
    try {
      const loweredEmail = email.toLowerCase();
      const userCredential = await createUserWithEmailAndPassword(auth, loweredEmail, password);
      const user = userCredential.user;
      await setDoc(doc(firestore, 'users', user.uid), {
        name,
        email: loweredEmail,
        role: 'explorer',
        createdAt: new Date().toISOString(),
      });
      navigation.replace('LocationSelector');
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  const handleGoogleSignup = async () => {
    if (Platform.OS === 'web') {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        const userRef = doc(firestore, 'users', user.uid);
        await setDoc(userRef, {
          name: user.displayName || 'User',
          email: user.email,
          role: 'explorer',
          createdAt: new Date().toISOString(),
        });

        navigation.replace('LocationSelector');
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    } else {
      promptAsync({ useProxy: true }).catch((error) => setErrorMessage(error.message));
    }
  };

  const handleAppleSignup = () => {
    Alert.alert("Coming Soon", "Apple signup will be available soon.");
  };

  return (
    <LinearGradient colors={['#FFDEE9', '#ffffff']} style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <View style={styles.innerContainer}>
        <Text style={styles.title}>Sign Up</Text>
        {errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
        <TextInput placeholder="Your Name" style={styles.input} value={name} onChangeText={setName} />
        <TextInput placeholder="Email" style={styles.input} value={email} onChangeText={setEmail} />
        <TextInput placeholder="Password" secureTextEntry style={styles.input} value={password} onChangeText={setPassword} />
        <TextInput placeholder="Confirm Password" secureTextEntry style={styles.input} value={confirmPassword} onChangeText={setConfirmPassword} />
        <TouchableOpacity style={styles.actionButton} onPress={handleSignup}>
          <Text style={styles.actionButtonText}>Sign Up</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.googleButton} onPress={handleGoogleSignup}>
          <Text style={styles.googleButtonText}>Sign up with Google</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.appleButton} onPress={handleAppleSignup}>
          <Text style={styles.appleButtonText}>Sign up with Apple</Text>
        </TouchableOpacity>
        <View style={styles.divider} />
        <View style={styles.linkContainer}>
          <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')}>
            <Text style={styles.linkText}>Already have an account? Login</Text>
          </TouchableOpacity>
        </View>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20 },
  innerContainer: { width: '80%' },
  title: { fontSize: 32, fontWeight: '700', textAlign: 'center', marginBottom: 20, color: '#4A4A4A' },
  input: { height: 50, backgroundColor: '#FFF0F6', borderColor: '#FFB6C1', borderWidth: 2, borderRadius: 25, paddingHorizontal: 15, marginBottom: 20 },
  actionButton: { backgroundColor: '#FFB6C1', paddingVertical: 15, borderRadius: 25, alignItems: 'center', marginBottom: 20 },
  actionButtonText: { color: '#FFF', fontWeight: '600' },
  errorText: { color: 'red', marginBottom: 10, textAlign: 'center' },
  googleButton: { backgroundColor: '#4285F4', paddingVertical: 15, borderRadius: 25, alignItems: 'center', marginBottom: 10 },
  googleButtonText: { color: '#FFF', fontWeight: '600' },
  appleButton: { backgroundColor: '#333333', paddingVertical: 15, borderRadius: 25, alignItems: 'center', marginBottom: 20 },
  appleButtonText: { color: '#FFF', fontWeight: '600' },
  divider: { height: 1, backgroundColor: '#FFB6C1', width: '100%', marginVertical: 20 },
  linkContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
  linkText: { color: '#4A4A4A', fontWeight: '500' },
});
